<template>
  <div class="withdrawal-dialog">
    <el-dialog title="提现申请" :visible.sync="visible" width="30%" :before-close="handleClose">
      <span>
        <el-form :model="withdrawalFormData" :rules="withdrawalRules" ref="withdrawalRef">
          <el-form-item label="提现金额：" prop="amount">
            <el-input-number v-model="withdrawalFormData.amount" :precision="2" :min="1" :max="parseFloat(balance)">
            </el-input-number>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="handleWithdrawal()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'withdrawalDialog',
    props: {
      visible: {
        default: false,
      },
      balance: {
        type: String,
        default: '0',
      },
    },
    data() {
      return {
        withdrawalFormData: {
          amount: '',
        },
        withdrawalRules: {
          amount: [{
            required: true,
            message: '提现金额不能为空'
          }]
        }
      }
    },
    methods: {
      handleClose() {
        this.$emit("update:visible", false);
      },
      handleWithdrawal(){
      }
    }
  }

</script>

<style lang="less" scoped>
  /deep/.el-dialog__body {
    padding: 20px;
  }

</style>
