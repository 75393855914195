<template>
  <div class="recharge-recod">
    <el-card>
      <el-table
        :data="rechargeRecodData"
        :header-cell-style="{
          'text-align': 'center',
          background: '#f2f2f2',
          color: '#838a96',
          border: 'none',
        }"
        :cell-style="{ 'text-align': 'center', color: '#000' }"
        style="width: 100%"
        v-loading="loading"
      >
        <el-table-column prop="addtime" label="时间">
          <template slot-scope="scope">
            {{ scope.row.addtime | formatDate }}
          </template>
        </el-table-column>
        <el-table-column prop="integral" label="积分"> </el-table-column>
        <el-table-column prop="consume_type" label="类型">
          <template slot-scope="scope">
            {{ scope.row.consume_type | billStatus }}
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-row class="pagination">
        <Pagination
          :page.sync="page"
          :limit.sync="limit"
          :total="rechargeCount"
          @getList="getRechargeRecodList"
        />
      </el-row>
    </el-card>
  </div>
</template>

<script>
 import Pagination from '@/components/Pagination/index.vue'
  import {
    getRechargeRecodListApi
  } from '@/api/account'
  export default {
    name: "rechargeRecod",
    data() {
      return {
        loading: false,
         rechargeRecodData: [],
         page:1,
         limit:10,
         rechargeCount:0
      }
    },
    components:{Pagination},
    filters: {
      billStatus(val) {
        let msg = ""
        switch (val) {
          case 1:
            msg = "充值";
            break;
          case 2:
            msg = "消费";
            break;
        }
        return msg
      }
    },
    methods: {
      //充值记录数据初始化
      async getRechargeRecodList() {
        this.loading = true
        const {
          data: res
        } = await getRechargeRecodListApi(this.page,this.limit)
        if (res.status != 10000) {
          return this.$message.error('账单明细数据初始化失败')
        }
        this.rechargeRecodData = res.result.list
        this.rechargeCount=res.result.count
        this.loading = false
      },
    }
  }

</script>

<style lang="less" scoped>
.pagination {
  margin-top: 20px;
  text-align: right;
}
</style>
