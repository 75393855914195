<template>
  <div class="my-balance">
    <div class="b-title">我的余额</div>
    <el-row class="b-money">
      <el-col :span="8" class="m-usable">
        可用余额(元)：
        <p>{{ companyAmountList.balance }}</p>
        <router-link to="/pc/recharge" class="m-btn">充值</router-link>
        <!-- <span class="m-Withdrawal" @click="withdrawal(companyAmountList.residue_amount)">提现</span> -->
      </el-col>
      <el-col :span="8" class="m-usable">
        我的积分：
        <p>{{ companyAmountList.score }}</p>
        <router-link to="/pc/credits" class="m-btn">兑换</router-link>
        <router-link to="/pc/integralRecharge" class="r-btn">充值</router-link>
      </el-col>
      <!-- <el-col :span="8" class="m-usable">
        盈利积分：
        <p>{{ companyAmountList.withdrawable_integral }}</p>
        <span class="m-btn" @click="withdrawal(companyAmountList.withdrawable_integral)">提现</span>
      </el-col> -->
    </el-row>
    <!-- <el-row class="b-total">
      <el-col :span="8">
        累计充值金额(元)：
        <p>{{ companyAmountList.total_amount }}</p>
      </el-col>
      <el-col :span="8">
        累计消费金额(元)：
        <p>{{ companyAmountList.cons_amount }}</p>
      </el-col>
      <el-col :span="8">
        累计提现积分：
        <p>{{ companyAmountList.total_withdrawal_integral }}</p>
      </el-col>
    </el-row> -->
    <el-tabs v-model="activeValue">
      <el-tab-pane :label="item.label" :name="item.id" v-for="(item) in billValue" :key="item.id">
        <el-card>
          <el-table :data="billDetailsData" :header-cell-style="{
              'text-align': 'center',
              background: '#f2f2f2',
              color: '#838a96',
              border: 'none',
            }" :cell-style="{ 'text-align': 'center', color: '#000' }" style="width: 100%" v-loading="loading">
            <el-table-column prop="addtime" label="时间">
              <template slot-scope="scope">
                {{ scope.row.add_time_text}}
              </template>
            </el-table-column>
            <el-table-column prop="num" label="积分">
              <template slot-scope="scope">
                {{ scope.row.score}}
              </template>
            </el-table-column>
            <el-table-column prop="num" label="来源">
              <template slot-scope="scope">
                {{ scope.row.tag_type_text}}
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-row class="pagination">
            <Pagination :page.sync="page" :limit.sync="limit" :total="billCount" @getList="getBillDetailsList" />
          </el-row>
        </el-card>
      </el-tab-pane>
    </el-tabs>
    <div class="d-bd">
      <!-- 暂无账单明细 -->
      <div class="b-nobill" v-if="isShowBill">
        <img src="../../../../assets/images/nobill.png" alt="" />
        <div class="b-txt">您还没有任何收支明细记录哦</div>
      </div>
    </div>
    <!-- 提现弹出层组件 -->
    <withdrawal-dialog :visible.sync="withdrawalVisible" :balance="balance"></withdrawal-dialog>
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination/index.vue'
  import {
    getCompanyAmountApi,
    getBillDetailsListApi,
  } from '@/api/account'
  import expendDetails from './components/expendDetails.vue'
  import rechargeRecod from './components/rechargeRecod.vue'
  import withdrawalDialog from './components/withdrawalDialog.vue'
  export default {
    name: "myBalance",
    data() {
      return {
        //是否有账单明细
        isShowBill: false,
        activeValue: '1',
        billValue: [{
            id: '1',
            label: '积分明细'
          },
          // {
          //   id: '2',
          //   label: '消费记录'
          // },
          // {
          //   id: '3',
          //   label: '积分兑换明细'
          // },
          // {
          //   id: '4',
          //   label: '提现明细'
          // }

        ],
        companyAmountList: {
          residue_amount: '0.00',
          integral: 0,
          withdrawable_integral: 0,
          total_amount: '0.00',
          cons_amount: '0.00',
          total_withdrawal_integral: 0
        },
        billDetailsData: [],
        loading: false,
        page: 1,
        limit: 10,
        billCount: 0,
        withdrawalVisible: false,
        balance: '0',
      }
    },
    components: {
      expendDetails,
      rechargeRecod,
      Pagination,
      withdrawalDialog
    },
    // filters: {
    //   billStatus(val) {
    //     let msg = ""
    //     switch (val) {
    //       case 1:
    //         msg = "充值";
    //         break;
    //       case 2:
    //         msg = "消费";
    //         break;
    //       case 3:
    //         msg = "兑换";
    //         break;
    //       case 4:
    //         msg = "提现";
    //         break;
    //     }
    //     return msg
    //   }
    // },
    watch: {
      activeValue: {
        handler(val) {
          this.activeValue = val
          this.page = 1
          this.getBillDetailsList()
        },
        immediate: true
      }
    },
    created() {
      this.getCompanyAmountList()
    },
    methods: {
      //总费用数据初始化
      async getCompanyAmountList() {
        this.loading = true
        const {
          data: res
        } = await getCompanyAmountApi()
        if (res.status != 10000) {
          return this.$message.error('账号金额初始化失败')
        }
        if (res.result.length != 0) {
          this.companyAmountList = res.result
          //将支付金额保存在store中
          this.$store.commit('saveAvailableBalance', this.companyAmountList.balance)
        }
        this.loading = false
      },
      //消费或充值账单明细
      async getBillDetailsList() {
        this.loading = true
        const {
          data: res
        } = await getBillDetailsListApi({
          // consume_type: this.activeValue,
          page: this.page,
          limit: this.limit
        })
        if (res.status != 10000) {
          return this.$message.error('账单明细数据初始化失败')
        }       
        this.billDetailsData = res.result.list
        this.billCount = res.result.count
        this.loading = false
      },
      //提现
      withdrawal(val) {
        this.withdrawalVisible = true
        this.balance = val
      }
    }
  }

</script>

<style lang="less" scoped>
  p {
    color: #ff7125 !important;
  }

  .my-balance {
    .b-title {
      height: 40px;
      line-height: 40px;
      font-size: 20px;
      font-weight: 500;
      border-bottom: 1px solid #e4e7ed;
      text-align: center;
    }

    .b-money {
      //   height: 80px;
      font-size: 14px;
      color: #969696;
      padding: 10px 0px;

      .m-usable {
        position: relative;

        .m-btn {
          position: absolute;
          top: 5%;
          right: 30%;
          font-size: 12px;
          color: #fff;
          background: #ff7125;
          padding: 5px;
          border-radius: 20%;
        }

        .m-Withdrawal {
          position: absolute;
          bottom: 5%;
          right: 30%;
          font-size: 12px;
          color: #fff;
          background: #ff7125;
          padding: 5px;
          border-radius: 20%;
        }

        a {
          text-decoration: none;
        }
      }

      p {
        margin-top: 15px;
        color: #2b1414;
        font-size: 32px;
      }
    }

    .b-total {
      font-size: 14px;
      color: #969696;
      padding: 10px 0px;
      border-top: 1px solid #e4e7ed;

      p {
        margin-top: 10px;
        color: #2b1414;
        font-size: 32px;
      }
    }

    .d-bd {
      text-align: center;
      color: #666;
      font-size: 14px;
      position: relative;
      // min-height: 500px;

      .b-nobill {
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translateX(-50%);
        width: 224px;
        height: 142px;
        margin-bottom: 20px;

        img {
          width: 224px;
          height: 142px;
        }
      }

      .b-table {
        border: 1px solid #e1251b;
        margin-top: 10px;

        .t-hd {
          height: 40px;
          background: #f5f5f5;
        }
      }
    }

    .pagination {
      margin-top: 20px;
      text-align: right;
    }
  }

  .r-btn{
    position: absolute;
    right: 30%;
    top: 55%;
    font-size: 12px;
    color: #fff;
    background: #ff7125;
    padding: 5px;
    border-radius: 20%;
  }
</style>
